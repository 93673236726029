/* dark-theme */
html[data-theme='dark'] div.SidePanel,html[data-theme='dark'] div.SidePanelLow{border:1px solid #444;background-image:-webkit-linear-gradient(#3f3f3f, #383838 99%, #5c5c5c);background-image:-o-linear-gradient(#3f3f3f, #383838 99%, #5c5c5c);background-image:linear-gradient(#3f3f3f, #383838 99%, #5c5c5c);background-repeat:no-repeat;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3f3f3f', endColorstr='#ff5c5c5c', GradientType=0);background-size:40px 40px;background-repeat:repeat-x;}
html[data-theme='dark'] div.SidePanel h2,html[data-theme='dark'] div.SidePanelLow h2{color:#dcdcdc;text-shadow:#444 1px 1px 0px;}
html[data-theme='dark'] div.menu_inner{background:#3d3d3f;border:1px solid #555;-webkit-box-shadow:0px 0px 4px #444, inset 0 1px 1px rgba(0, 0, 0, 0.01);-moz-box-shadow:0px 0px 4px #444, inset 0 1px 1px rgba(0, 0, 0, 0.01);box-shadow:0px 0px 4px #444, inset 0 1px 1px rgba(0, 0, 0, 0.01);}
html[data-theme='dark'] body{background-color:#2d2e2f;color:#FFF;}
html[data-theme='dark'] input[type=text]{background:#303132;color:#e0e0e1;}
html[data-theme='dark'] a:link,html[data-theme='dark'] a:active,html[data-theme='dark'] a:visited{color:#EEE;}
html[data-theme='dark'] a:hover{color:#02A3FB;}
html[data-theme='dark'] hr.board{background-color:#444;color:#444;}
html[data-theme='dark'] a.l,html[data-theme='dark'] a.l:link,html[data-theme='dark'] a.l:visited,html[data-theme='dark'] a.l:active{color:#2ea6ff;}
html[data-theme='dark'] a.l:hover{color:#02A3FB;}
html[data-theme='dark'] a.p,html[data-theme='dark'] a.p:active,html[data-theme='dark'] a.p:visited{color:#dcdcdc;background:#6e6e6e;}
html[data-theme='dark'] a.p:hover{color:#FFF;background:#2ea6ff;}
html[data-theme='dark'] h2.subtitle{color:#dedede;}
html[data-theme='dark'] #header h1{color:#FFF;}
html[data-theme='dark'] #columnSubjectBrowserB .sideInner{background-color:#3d3d3f;border:1px solid #444;}
html[data-theme='dark'] #columnSubjectBrowserB .sideInner h2{background-color:#303030;}
html[data-theme='dark'] #navMenuNeue li a.top,html[data-theme='dark'] #navMenuNeue li a.focus,html[data-theme='dark'] #navMenuNeue li a.top_lite{color:#c6c6c6; text-shadow: None}
html[data-theme='dark'] #navMenuNeue li a.top:hover,html[data-theme='dark'] #navMenuNeue li a.top_lite:hover,html[data-theme='dark'] #navMenuNeue li:hover a.top{color:#e9e9e9;  text-shadow: None}
html[data-theme='dark'] #navMenuNeue li a.focus,html[data-theme='dark'] #navMenuNeue li a.top_lite:hover{color:#369CF8;}
html[data-theme='dark'] #navMenuNeue li ul li .sep{background-color:#5c5c5c;color:#dcdcdc;border-bottom:1px rgba(255, 255, 255, 0.1) solid;}
html[data-theme='dark'] #navMenuNeue li ul{background:rgba(80, 80, 80, 0.7);}
html[data-theme='dark'] #navMenuNeue li ul li a{color:#FFF;}
html[data-theme='dark'] #navMenuNeue li ul.explore li{border-bottom:1px solid rgba(255, 255, 255, 0.1);}
html[data-theme='dark'] #navMenuNeue li ul.explore li a:hover{background:#369CF8;}
html[data-theme='dark'] #navMenuNeue li ul.explore li.single a{color:#FFF;}
html[data-theme='dark'] #footer #footerLinks{background:#3d3d3d;border-top:1px solid #444;}
html[data-theme='dark'] #footer #footerLinks li dt{border-bottom:1px solid #666;}
html[data-theme='dark'] #browserTools{border-bottom:1px solid #444;}
html[data-theme='dark'] #headerNeue2{background:#37393b;border-bottom:1px solid #333;box-shadow:0px 0 0px 1px rgba(0, 0, 0, 0.4);background-color:#37393b;background:-webkit-gradient(linear, left top, left bottom, from(#37393b), to(#333));background:-webkit-linear-gradient(top, #37393b, #333);background:-moz-linear-gradient(top, #37393b, #333);background:-ms-linear-gradient(top, #37393b, #333);background:-o-linear-gradient(top, #37393b, #333);}
html[data-theme='dark'] #headerNeue2 #headerSearch{border:1px solid #333;background-color:#6e6e6e;}
html[data-theme='dark'] #headerNeue2 #headerSearch select{border-right:1px solid #333;color:#e0e0e1;}
html[data-theme='dark'] #navNeue2 #navMenuNeue li a.top:hover,html[data-theme='dark'] #navNeue2 #navMenuNeue li a.top_lite:hover,html[data-theme='dark'] #navNeue2 #navMenuNeue li:hover a.top,html[data-theme='dark'] #navNeue2 #navMenuNeue li a.focus:hover{color:#fff;}
html[data-theme='dark'] #navNeue2 #navMenuNeue li a.chl{background-color:#5c5c5c;background:-webkit-gradient(linear, left top, left bottom, from(#5c5c5c), to(#5c5c5c));background:-webkit-linear-gradient(top, #5c5c5c, #5c5c5c);background:-moz-linear-gradient(top, #5c5c5c, #5c5c5c);background:-ms-linear-gradient(top, #5c5c5c, #5c5c5c);background:-o-linear-gradient(top, #5c5c5c, #5c5c5c);}
html[data-theme='dark'] #wrapperNeue #columnSubjectBrowserA #browserTools{border-top:1px solid #555;border-bottom:1px solid #444;}
html[data-theme='dark'] ul.browserFull li.item{background-color:transparent;border-bottom:1px solid #444;}
html[data-theme='dark'] ul.browserFull li.item.even{background-color:rgba(255, 255, 255, 0.03);border-top:1px solid #555;}
html[data-theme='dark'] ul.browserFull li.item span.rank{background:#02A3FB;}
html[data-theme='dark'] ul.collect_dropmenu{background-color:rgba(80, 80, 80, 0.7);border:1px solid #6e6e6e;-webkit-box-shadow:2px 2px 5px #444;-moz-box-shadow:2px 2px 5px #444;box-shadow:2px 2px 5px #444;}
html[data-theme='dark'] ul.collect_dropmenu li a{border-left:1px solid #444;border-right:1px solid #333;}
html[data-theme='dark'] ul.collect_dropmenu li:first-child a{border-left:none;}
html[data-theme='dark'] ul.collect_dropmenu li:last-child a{border-right:none;}
html[data-theme='dark'] ul.collect_dropmenu li:last-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:5px;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:0;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:5px;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:0;border-top-right-radius:5px;border-bottom-right-radius:5px;border-bottom-left-radius:0;border-top-left-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
html[data-theme='dark'] input.inputtext{background-color:#303132;color:#e0e0e1;border:1px solid #5c5c5c;}
html[data-theme='dark'] input.inputtext:focus{border:1px solid #D98D88 #F4A8BC #F4A8BC #D98D88;background-color:#2f2f30;color:#e0e0e1;}
html[data-theme='dark'] #dock .content{background:rgba(59, 59, 59, 0.7);color:#d8d8d8;}
html[data-theme='dark'] #dock ul li{border-right:1px solid #666;}
html[data-theme='dark'] #dock ul li.first{border-left:none;}
html[data-theme='dark'] #dock ul li.last{border-right:none;}
html[data-theme='dark'] #headerNeue2 .menuCompact{background:#5c5c5c;color:#c6c6c6;}
html[data-theme='dark'] #navMenuNeueToggle:checked~#navNeue2{background:rgba(80,80,80,0.7);}
html[data-theme='dark'] #navMenuNeueToggle:checked~#headerSearchWrapper{background:#2d2e2f;}
html[data-theme='dark'] .removable{color:#FFFFFF; margin-left: 5px;}
html[data-theme='dark'] .site-title {color:#fff;}
html[data-theme='dark'] .site-link {color:#fff;}
html[data-theme='dark'] #nav {background:#37393b;border-bottom:1px solid #333;box-shadow:0px 0 0px 1px rgba(0, 0, 0, 0.4);background-color:#37393b;background:-webkit-gradient(linear, left top, left bottom, from(#37393b), to(#333));background:-webkit-linear-gradient(top, #37393b, #333);background:-moz-linear-gradient(top, #37393b, #333);background:-ms-linear-gradient(top, #37393b, #333);background:-o-linear-gradient(top, #37393b, #333);}
/*https://stackoverflow.com/questions/63212723/using-dark-mode-in-react-select*/
html[data-theme='dark'] .my-react-select-container .my-react-select__control {
    background-color:#6e6e6e;
  }

html[data-theme='dark']  .my-react-select-container .my-react-select__control--is-focused {
    background-color:#6e6e6e;
  }

html[data-theme='dark']  .my-react-select-container .my-react-select__menu {
    background-color:#6e6e6e;
  }

html[data-theme='dark']  .my-react-select-container .my-react-select__option {
    /*background-color:#6e6e6e;*/
    color:#FFF;
    background-color: rgb(64 64 64);
  }
html[data-theme='dark']  .my-react-select-container .my-react-select__option:hover{
    /*background-color:#6e6e6e;*/
    color:#FFF;
    background-color: rgb(38 38 38);
  }
html[data-theme='dark']  .my-react-select-container .my-react-select__option--is-focused {
    background-color: rgb(38 38 38);
}

html[data-theme='dark']  .my-react-select-container .my-react-select__indicator-separator {
    background-color:#6e6e6e;
  }

html[data-theme='dark']  .my-react-select-container .my-react-select__input-container,
  /*.my-react-select-container .my-react-select__placeholder,*/
  .my-react-select-container .my-react-select__single-value {
    background-color:#6e6e6e;
  }
html[data-theme='dark'] .my-react-select-container .my-react-select__placeholder{
    color:#FFF;
    background-color: rgb(64 64 64);
}
/*html[data-theme='dark'] my-react-select__value-container my-react-select__value-container--is-multi {*/
/*   color:#FFF;*/
/*    background-color: rgb(64 64 64);*/
/*}*/

/*html[data-theme='dark'] #tagSearchInput.div {background-color:#6e6e6e;}*/
/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {display: flex;flex-direction: row;align-items: center;gap: 4px;}
.toggle-theme-wrapper span {font-size: 14px;}
.toggle-theme {position: relative;display: inline-block;height: 13px;width: 30px;}
.toggle-theme input {display: none;}
.slider {background-color: #ccc;position: absolute;cursor: pointer;bottom: 0;left: 0;right: 0;top: 0;transition: 0.2s;}
.slider:before {background-color: #fff;bottom: 0px;content: "";height: 13px;left: 0px;position: absolute;transition: 0.4s;width: 13px;}
input:checked + .slider:before {transform: translateX(17px);}
input:checked + .slider {background-color: cornflowerblue;}
.slider.round {border-radius: 34px;}
.slider.round:before {border-radius: 50%;}