/*! CSS Used from: https://bangumi.tv/min/g=css?r351 */
body{margin:0px;padding:0px;font-size:12px;font-family:'SF Pro SC','SF Pro Display','PingFang SC','Lucida Grande','Helvetica Neue',Helvetica,Arial,Verdana,sans-serif,"Hiragino Sans GB";line-height:150%;background-color:#FFF;color:#000;}
body,div,dl,dt,dd,ul,li,h1,h2,h3,form,input,select,p{margin:0;padding:0;}
input,select{vertical-align:baseline;}
input[type=text]{background:#FFF;color:#000;font-size:12px;outline:none;}
label{cursor:pointer;}
img{border:0;}
strong{font-weight:bold;font-style:normal;}
ul{list-style:none;}
small{-webkit-text-size-adjust:none;text-size-adjust:none;}
.clear{clear:both;line-height:0;font-size:0;}
.clearit:after{content:".";display:block;height:0;clear:both;visibility:hidden;}
.clearit{zoom:1;}
.ll{float:left;}
.currentSelectTags{display: flex; flex-direction: row}
.removable{color:#000000; margin-left: 5px; background: #f09199; border-radius: 7px}
.removable:hover{color:#02A3FB;text-decoration:underline}
.removable:after{
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}
#nav {
    position:relative;min-width:980px;height:50px;background:#FCFCFC;border-bottom:1px solid #DDD;box-shadow:0px 0 0px 1px rgba(250, 250, 250, 0.8);background-color:#FCFCFC;background:-webkit-gradient(linear, left top, left bottom, from(#FCFCFC), to(#FAFAF9));background:-webkit-linear-gradient(top, #FCFCFC, #FAFAF9);background:-moz-linear-gradient(top, #FCFCFC, #FAFAF9);background:-ms-linear-gradient(top, #FCFCFC, #FAFAF9);background:-o-linear-gradient(top, #FCFCFC, #FAFAF9);
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}
#nav ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}
#nav li a.top,#nav li a.focus{display:block;font-size:14px;font-weight:bold;text-align:center;text-shadow:1px 1px #eee;float:left;padding:0 15px;line-height:35px;color:#777;}
#nav li a.top:hover,#nav li:hover a.top{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);color:#333;}
.site-title{
    font-size: 1.5rem;
    text-align:center;text-shadow:1px 1px #eee;float:left;padding:0 15px;line-height:35px;color:#777;
}
.site-link{
    font-size: 1rem;
    text-align:center;float:left;padding:0 15px;line-height:35px;color:#777;
}
#nav a:hover{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);color:#333;}
::-moz-focus-inner{border:none;padding:0;}
a:link,a:active,a:visited{text-decoration:none;color:#444;}
a:hover{text-decoration:none;color:#02A3FB;}
hr.board{height:1px;border:0;background-color:#EEE;color:#EEE;}
a.nav,a.nav:link,a.nav:visited,a.nav:active{color:#EFEFEF;border:0;text-decoration:none;}
a.nav:hover{color:#fff;}
a.l,a.l:link,a.l:visited,a.l:active{color:#0084B4;border:0;text-decoration:none;}
a.l:hover{color:#02A3FB;text-decoration:underline;}
div.page_inner{padding:10px 0;}
a.p,a.p:active,a.p:visited{padding:4px 8px;margin-right:4px;font-size:12px;text-decoration:none;font-weight:normal;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;color:#555;background:#EEE;}
a.p:hover{color:#FFF;background:#0084B4;}
span.p_edge{margin:0 4px;font-size:11px;color:#777;font-weight:normal;padding:4px 5px 3px;}
strong.p_cur{margin-right:4px;font-size:12px;color:#FFF;font-weight:normal;padding:4px 8px;display:inline;background:#F09199;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
a.p_pages input.inputtext{padding:3px 5px;}
a.chiiBtn{display:inline-block;text-decoration:none;line-height:20px;margin:0 5px 5px 0;padding:0 12px;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;color:#666;border:1px solid #DDD;text-shadow:0px 1px 2px #FFF;background-image:-webkit-linear-gradient(top, #FFF 0%, #FAFAFA 100%);background-image:-o-linear-gradient(top, #FFF 0%, #FAFAFA 100%);background-image:linear-gradient(to bottom, #FFF 0%, #FAFAFA 100%);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fffafafa', GradientType=0);-webkit-box-shadow:0 1px 2px #EEE, inset 0 1px 1px #FFF;-moz-box-shadow:0 1px 2px #EEE, inset 0 1px 1px #FFF;box-shadow:0 1px 2px #EEE, inset 0 1px 1px #FFF;}
a.chiiBtn:hover{text-shadow:none;-webkit-box-shadow:0 0 3px #EEE, inset 0 -1px 5px rgba(0, 0, 0, 0.1);-moz-box-shadow:0 0 3px #EEE, inset 0 -1px 5px rgba(0, 0, 0, 0.1);box-shadow:0 0 3px #EEE, inset 0 -1px 5px rgba(0, 0, 0, 0.1);color:#FFF;background-image:-webkit-linear-gradient(top, #5FA3DB 0%, #72B6E3 100%);background-image:-o-linear-gradient(top, #5FA3DB 0%, #72B6E3 100%);background-image:linear-gradient(to bottom, #5FA3DB 0%, #72B6E3 100%);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5fa3db', endColorstr='#ff72b6e3', GradientType=0);}
small.fade{font-size:10px;color:#F90;}
small.grey{font-size:10px;color:#999;}
h2.subtitle{font-weight:300;font-size:18px;padding:0 5px 0 0;line-height:140%;color:#555;}
.tip{font-size:12px;color:#666;}
.tip_i{font-size:12px;color:#CCC;text-shadow:none;}
.tip_j{font-size:12px;color:#999;text-shadow:none;}
#main{margin:0 auto;width:1000px;}
#headerSearch{position:absolute;right:0;margin:10px 0 0 0;border:1px solid #F19299;background:rgba(255, 255, 255, 0.8);-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-moz-box-shadow:0px 1px 0 rgba(255, 255, 255, 0.3), 0px 0 2px rgba(0, 0, 0, 0.3) inset;-webkit-box-shadow:0px 1px 0 rgba(255, 255, 255, 0.3), 0px 0 2px rgba(0, 0, 0, 0.3) inset;box-shadow:0 0 1px rgba(0, 0, 0, 0.3) inset, 0px 1px 0 rgba(255, 255, 255, 0.3);}
#headerSearch .inner{padding:3px 5px;}
#headerSearch input.textfield{background:transparent;line-height:20px;width:160px;border:none;-webkit-appearance:caret;}
#headerSearch input.textfield:focus{outline:none;}
#headerSearch input.search{text-indent:-9999px;width:20px;height:20px;background:transparent url('https://bangumi.tv/img/rc2/ico_search.png') 50% 50% no-repeat;color:#FFF;border:none;cursor:pointer;}
#headerSearch input.search:focus{outline:none;}
#header{padding:15px 0 10px 0;position:relative;}
#header h1{color:#3E3E3E;font-size:20px;font-size-adjust:none;font-stretch:normal;font-style:normal;font-variant:normal;font-weight:bold;display:inline;}
.columns{padding:0 0 10px 0;width:1000px;}
.column{float:left;}
#columnSubjectBrowserA{margin:5px 15px 0 0;width:700px;}
#columnSubjectBrowserB{margin:5px 0 0 0;width:260px;}
#columnSubjectBrowserB .sideInner{background-color:#FAFAFA;border:1px solid #EEE;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;padding:5px 0 0 0;margin:0 0 10px 0;}
#columnSubjectBrowserB .sideInner h2{border:none;background-color:#FFF;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;padding:5px 10px;}
#columnSubjectBrowserB ul.grouped{margin:5px 0;}
#columnSubjectBrowserB ul.grouped li{float:left;padding:3px 0 3px 10px;}
#columnSubjectBrowserB ul.grouped li a{display:block;padding:2px 5px;}
#columnSubjectBrowserB ul.grouped li a:hover,#columnSubjectBrowserB ul.grouped li a.focus{background:#F09199;color:#FFF;text-decoration:none;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
#columnSubjectBrowserB ul#airTimeMenu li{font-size:11px;width:50px;}
#browserTools a.l:hover, #browserTools a.l.focus{background:#F09199;color:#FFF;text-decoration:none;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
#menuNeue{width:980px;margin:0 auto;padding:0;text-align:center;}
#navMenuNeue li{float:left;}
#navMenuNeue li a.top,#navMenuNeue li a.focus,#navMenuNeue li a.top_lite{display:block;font-size:14px;font-weight:bold;text-align:center;text-shadow:1px 1px #eee;float:left;padding:0 15px;line-height:35px;color:#777;}
#navMenuNeue li a.top:hover,#navMenuNeue li a.top_lite:hover,#navMenuNeue li:hover a.top{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);color:#333;}
#navMenuNeue li a.focus:hover{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);}
#navMenuNeue li:hover a.top span{display:block;float:left;text-shadow:1px 1px #CCC;}
#navMenuNeue li:hover a.focus span{background:#FFF;}
#navMenuNeue li a.focus,#navMenuNeue li a.top_lite:hover{display:block;font-size:14px;font-weight:bold;text-align:center;text-shadow:1px 1px #fff;color:#369CF8;}
#navMenuNeue a.nav{display:block;}
#navMenuNeue li ul li .sep{display:block;width:98px;margin:2px 0;padding:2px 10px;line-height:16px;text-align:left;background-color:#F1F1F1;color:#555;border-bottom:1px #eee solid;}
#navMenuNeue li ul{display:block;position:absolute;left:-999em;z-index:90;width:118px;padding:0;background:#FFF;background:-webkit-gradient(linear, left top, left bottom, color-stop(0.8, rgba(254, 254, 254, 0.9)), color-stop(1, rgba(250, 250, 250, 0.95)));background:-moz-linear-gradient(center top, rgba(254, 254, 254, 0.9) 80%, rgba(250, 250, 250, 0.95) 100%);background:linear-gradient(center top, rgba(254, 254, 254, 0.9) 80%, rgba(250, 250, 250, 0.95) 100%);-moz-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);-webkit-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);-moz-border-radius:0 0 5px 5px;-webkit-border-radius:0 0 5px 5px;border-radius:0 0 5px 5px;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(254, 254, 254, 0.9);}
#navMenuNeue li ul li{float:none;}
#navMenuNeue li:hover ul{left:auto;}
#navMenuNeue li ul li a{color:#0187C5;text-align:center;padding:3px 0px;color:#0084B4;}
#navMenuNeue li ul li a:hover{color:#FFF;background:#4F93CF;background:-moz-linear-gradient(top, #6BA6D8, #4F93CF);background:-o-linear-gradient(top, #6BA6D8, #4F93CF);background:-webkit-gradient(linear, left top, left bottom, from(#6BA6D8), to(#4F93CF));}
#navMenuNeue li ul li:first-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:0;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:5px;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:0;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:5px;border-top-right-radius:5px;border-bottom-right-radius:0;border-bottom-left-radius:0;border-top-left-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#navMenuNeue li ul li:last-child a:hover{-webkit-border-top-right-radius:0;-webkit-border-bottom-right-radius:5px;-webkit-border-bottom-left-radius:5px;-webkit-border-top-left-radius:0;-moz-border-radius-topright:0;-moz-border-radius-bottomright:5px;-moz-border-radius-bottomleft:5px;-moz-border-radius-topleft:0;border-top-right-radius:0;border-bottom-right-radius:5px;border-bottom-left-radius:5px;border-top-left-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#navMenuNeue li ul.explore{width:170px;text-align:left;}
#navMenuNeue li ul.explore li{border-bottom:1px solid #eee;}
#navMenuNeue li ul.explore li a{display:inline-block;text-align:left;padding:2px 10px;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;}
#navMenuNeue li ul.explore li a:hover{background:#369CF8;}
#navMenuNeue li ul.explore li span{display:block;padding:2px 0;}
#navMenuNeue li ul.explore li.group{padding:5px 10px;}
#navMenuNeue li ul.explore li.group span.root{display:inline-block;color:#888;background:#F5F5F5;font-weight:bold;padding:2px 5px;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
#navMenuNeue li ul.explore li.single a{display:block;background:none;text-align:left;padding:5px 20px;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;color:#0084B4;}
#navMenuNeue li ul.explore li.single a:hover{background:#4F93CF;color:#FFF;background:-moz-linear-gradient(top, #6BA6D8, #4F93CF);background:-o-linear-gradient(top, #6BA6D8, #4F93CF);background:-webkit-gradient(linear, left top, left bottom, from(#6BA6D8), to(#4F93CF));}
#navMenuNeue li ul.explore li.single:first-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:0;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:5px;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:0;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:5px;border-top-right-radius:5px;border-bottom-right-radius:0;border-bottom-left-radius:0;border-top-left-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#tagSearch .inner{margin:0;}
#tagSearch input.inputtext{width:65%;}
#tagSearch div.search{float:right;padding:5px 5px 0 0;}
#footer{margin:20px 0 15px 0;padding:0;color:#AAA;}
#footer #footerLinks{display:block;overflow:hidden;background:#f8f8f8;border-top:1px solid #EEE;margin:0 0 0 0;padding:15px 10px;}
#footer #footerLinks li{display:block;float:left;width:25%;}
#footer #footerLinks li dt{font-size:12px;font-weight:bold;margin-bottom:3px;margin-left:15px;padding-bottom:3px;width:177px;border-bottom:1px solid #CCC;}
#footer #footerLinks li dd{margin-bottom:0.2em;margin-left:15px;}
#airTimeMenu{position:relative;}
#airTimeMenu li.airYear,#airTimeMenu li#pastAirTime,#airTimeMenu li#futureAirTime{position:relative;}
#airTimeMenu li.airYear a,#airTimeMenu li#pastAirTime a,#airTimeMenu li#futureAirTime a{display:block;}
#browserTools{padding:0 0 0 10px;border-bottom:1px solid #EEE;}
ul.browserTypeSelector{float:right;height:20px;}
ul.browserTypeSelector li{float:left;height:25px;}
ul.browserTypeSelector li a{background-image:url("https://bangumi.tv/img/b5/btn_dsp_mode.png");display:block;overflow:hidden;height:17px;width:25px;text-indent:-9999px;}
ul.browserTypeSelector li a#full_selector{background-position:-25px 0;}
ul.browserTypeSelector li a#grid_selector{background-position:-50px 0;}
ul.browserTypeSelector li a#full_selector.active{background-position:-25px bottom;}
#wrapperNeue{min-width:980px;}
#headerNeue2{position:relative;min-width:980px;height:50px;background:#FCFCFC;border-bottom:1px solid #DDD;box-shadow:0px 0 0px 1px rgba(250, 250, 250, 0.8);background-color:#FCFCFC;background:-webkit-gradient(linear, left top, left bottom, from(#FCFCFC), to(#FAFAF9));background:-webkit-linear-gradient(top, #FCFCFC, #FAFAF9);background:-moz-linear-gradient(top, #FCFCFC, #FAFAF9);background:-ms-linear-gradient(top, #FCFCFC, #FAFAF9);background:-o-linear-gradient(top, #FCFCFC, #FAFAF9);}
#headerNeue2 div.bg{position:absolute;left:-40px;background:url('https://bangumi.tv/img/rc3/bg_musume.png') 0 -10px no-repeat;width:40px;height:50px;}
#headerNeue2 div.musume_6{background-position:-240px 0;}
#headerNeue2 .headerNeueInner{width:1000px;margin:0 auto;padding:0;position:relative;}
#headerNeue2 a.logo{margin:0 5px 0 0;float:left;display:block;text-indent:-9999px;width:140px;height:50px;background:transparent url('https://bangumi.tv/img/rc3/logo.png') left 50% no-repeat;}
#headerNeue2 #headerSearch{float:right;position:relative;margin:10px 10px 0 10px;-moz-border-radius:100px;-webkit-border-radius:100px;border-radius:100px;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;border:1px solid #DDD;background-color:rgba(255, 255, 255, 0.2);}
#headerNeue2 #headerSearch input.textfield{width:120px;-webkit-appearance:none;-moz-appearance:none;box-shadow:none;}
#headerNeue2 #headerSearch select{padding:4px 0 4px 5px;min-width:35px;border:none;outline:none;box-shadow:none;background-color:transparent;background-image:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;-moz-border-radius:0;-webkit-border-radius:0;border-radius:0;border-right:1px solid #DDD;color:#000;}
#navNeue2{float:left;}
#navNeue2 #menuNeue{width:510px;}
#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{padding:6px 5px 5px 5px;line-height:40px;}
#navNeue2 #navMenuNeue li:hover a.focus span{background:transparent;}
#navNeue2 #navMenuNeue li a.top:hover,#navNeue2 #navMenuNeue li a.top_lite:hover,#navNeue2 #navMenuNeue li:hover a.top,#navNeue2 #navMenuNeue li a.focus:hover{line-height:36px;border-top:2px solid #F09199;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;color:#333;}
#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{font-weight:normal;text-shadow:none;}
#navNeue2 #navMenuNeue li:hover a.top span{text-shadow:none;}
#navNeue2 #navMenuNeue li a.chl{font-weight:bold;margin:8px 0 0 0;padding:5px 8px;line-height:25px;background-color:#F5F5F5;background:-webkit-gradient(linear, left top, left bottom, from(#F5F5F5), to(#F1F1F1));background:-webkit-linear-gradient(top, #F5F5F5, #F1F1F1);background:-moz-linear-gradient(top, #F5F5F5, #F1F1F1);background:-ms-linear-gradient(top, #F5F5F5, #F1F1F1);background:-o-linear-gradient(top, #F5F5F5, #F1F1F1);}
#navNeue2 #navMenuNeue li a.chl:hover{border:none;line-height:25px;background:#F09199;color:#FFF;text-shadow:none;}
#navNeue2 #navMenuNeue li:hover a.chl{border:none;line-height:25px;background:#F09199;color:#FFF;text-shadow:none;}
#navNeue2 #navMenuNeue li a.anime{padding-left:15px;-moz-border-radius:100px 0 0 100px;-webkit-border-radius:100px 0 0 100px;border-radius:100px 0 0 100px;}
#navNeue2 #navMenuNeue li a.real{padding-right:15px;margin-right:5px;-moz-border-radius:0 100px 100px 0;-webkit-border-radius:0 100px 100px 0;border-radius:0 100px 100px 0;}
#navNeue2 #navMenuNeue li ul{-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-moz-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);-webkit-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);}
#headerNeue2 .menuCompact,#headerNeue2 #navMenuNeueToggle{display:none;}
#wrapperNeue #columnSubjectBrowserA #browserTools{line-height:20px;font-size:13px;background:transparent;padding:5px 0;color:#AAA;border-top:1px solid #e8e8e8;border-bottom:1px solid #EAEAEA;}
ul.browserFull h3{font-weight:normal;font-size:14px;}
ul.browserFull li.item{background-color:transparent;border-bottom:1px solid #EAEAEA;padding:7px 5px;position:relative;}
ul.browserFull li.item.even{background-color:#f9f9f9;border-top:1px solid #FAFAFA;}
ul.browserFull li.item img.cover{margin-bottom:0;max-width:78px;}
ul.browserFull li.item div.inner{margin-left:100px;}
ul.browserFull li.item div.inner div.collectBlock{position:absolute;right:5px;top:30px;}
ul.browserFull li.item p.info{padding:10px 0 0 0;}
ul.browserFull li.item p.rateInfo{padding:10px 0 0 0;}
ul.browserFull li.item span.rank{position:absolute;right:10px;top:5px;font-size:15px;line-height:100%;padding:2px 5px 1px 5px;color:#FFF;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;background:#02A3FB;}
ul.browserFull li.item span.rank small{font-size:10px;color:#EEE;}
div.collectBlock p.collectModify{background:#FFF url('https://bangumi.tv/img/ico/ico_list_love.gif') no-repeat 5px 50%;padding:2px 5px 2px 17px;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;background-color:#FEFEFE;border:1px solid #EEE;-webkit-box-shadow:0px 2px 5px #EEE;-moz-box-shadow:0px 2px 5px #EEE;box-shadow:0px 2px 5px #EEE;}
div.collectBlock p.collectModify a{color:#666;}
ul.collectMenu{margin:0;padding:0;}
ul.collectMenu li{margin:0;float:none;}
ul.collectMenu li:hover ul{display:block;}
ul.collect_dropmenu{display:none;position:absolute;left:-5px;top:-5px;z-index:99;width:230px;padding:0;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background-color:rgba(254, 254, 254, 0.9);border:1px solid #DDD;-webkit-box-shadow:2px 2px 5px #EEE;-moz-box-shadow:2px 2px 5px #EEE;box-shadow:2px 2px 5px #EEE;}
ul.collect_dropmenu li{float:left;margin:0;width:auto;}
ul.collect_dropmenu li a{display:block;padding:5px 10px;border-left:1px solid #EEE;border-right:1px solid #FFF;}
ul.collect_dropmenu li a:hover{background:#369CF8;color:#FFF;}
ul.collect_dropmenu li:first-child a{border-left:none;}
ul.collect_dropmenu li:last-child a{border-right:none;}
ul.collect_dropmenu li:first-child a:hover{-webkit-border-top-right-radius:0;-webkit-border-bottom-right-radius:0;-webkit-border-bottom-left-radius:5px;-webkit-border-top-left-radius:5px;-moz-border-radius-topright:0;-moz-border-radius-bottomright:0;-moz-border-radius-bottomleft:5px;-moz-border-radius-topleft:5px;border-top-right-radius:0;border-bottom-right-radius:0;border-bottom-left-radius:5px;border-top-left-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
ul.collect_dropmenu li:last-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:5px;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:0;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:5px;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:0;border-top-right-radius:5px;border-bottom-right-radius:5px;border-bottom-left-radius:0;border-top-left-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
input[type=text],select{-webkit-transition:border linear 0.2s, box-shadow linear 0.2s;-moz-transition:border linear 0.2s, box-shadow linear 0.2s;transition:border linear 0.2s, box-shadow linear 0.2s;-webkit-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1);-moz-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 1px 3px rgba(0,0,0,0.1);}
input.inputtext{font-size:15px;padding:5px 5px;line-height:22px;width:98%;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;background-color:#FFF;color:#000;border:1px solid #d9d9d9;}
input.inputtext:focus{-webkit-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);-moz-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);border:1px solid #D98D88 #F4A8BC #F4A8BC #D98D88;background-color:#FFF;color:#000;}
input.btnPink{border:0px;background:#F09199;color:#FFF;cursor:pointer;margin:5px 0;padding:5px 25px;font-size:14px;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;line-height:150%;-webkit-appearance:none;}
input.btnPink:hover{background:#369CF8;color:#FFF;}
select.form{border-width:1px;border-style:solid;border-color:#999 #CCC #DDDDDD #999;padding:3px;font-size:14px;}
select.form:focus{border-width:1px;border-style:solid;border-color:#FD0B98 #FFADD1 #FFADD1 #FD0B98;padding:3px;}
img.cover{background-color:#FFF;margin-bottom:5px;padding:2px;border-top:1px solid #C7C7C9;border-left:1px solid #A9A9AB;border-right:1px solid #A9A9AB;border-bottom:1px solid #858486;-webkit-box-shadow:0 1px 5px #AAA;-moz-box-shadow:0 1px 5px #AAA;box-shadow:0 1px 5px #AAA;}
a.cover:hover img.cover{background-color:#FFF;border:1px solid #0187C5;padding:2px;-webkit-box-shadow:0 1px 5px #0187C5;-moz-box-shadow:0 1px 5px #0187C5;box-shadow:0 1px 5px #0187C5;}
.starstop-s{background:transparent url(https://bangumi.tv/img/ico/rate_star_2x.png);height:10px;background-size:10px 19.5px;background-position:100% 100%;background-repeat:repeat-x;width:50px;display:inline-block;float:none;}
.starstop-s .starlight{background:transparent url(https://bangumi.tv/img/ico/rate_star_2x.png);height:10px;background-size:10px 19.5px;background-position:100% 100%;background-repeat:repeat-x;display:block;width:100%;background-position:0 0;}
.starstop-s .starlight.stars1{width:5px;}
.starstop-s .starlight.stars2{width:10px;}
.starstop-s .starlight.stars3{width:15px;}
.starstop-s .starlight.stars4{width:20px;}
.starstop-s .starlight.stars5{width:25px;}
.starstop-s .starlight.stars6{width:30px;}
.starstop-s .starlight.stars7{width:35px;}
.starstop-s .starlight.stars8{width:40px;}
.starstop-s .starlight.stars9{width:45px;}
span.avatarNeue{display:inline-block;background:transparent no-repeat 50% 50%;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-webkit-box-shadow:inset #BBB 0px 0 2px 0px;-moz-box-shadow:inset #BBB 0px 0 2px 0px;box-shadow:inset #BBB 0px 0 2px 0px;-webkit-transition:border linear 0.2s, box-shadow linear 0.2s;-moz-transition:border linear 0.2s, box-shadow linear 0.2s;transition:border linear 0.2s, box-shadow linear 0.2s;}
span.avatarSize32{width:32px;height:32px;background-size:cover;}
a.avatar:hover span.avatarNeue{-moz-box-shadow:inset #0187C5 0px 0px 4px 0px;-webkit-box-shadow:inset #0187C5 0px 0px 4px 0px;box-shadow:inset #0187C5 0px 0px 4px 0px;}
#dock{position:fixed;bottom:0px;right:30px;z-index:99;}
#dock .content{padding:3px 10px;font-size:12px;position:relative;-webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);-moz-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);-moz-border-radius:5px 5px 0 0;-webkit-border-radius:5px 5px 0 0;border-radius:5px 5px 0 0;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(250, 250, 250, 0.8);color:#666;}
#dock ul li{float:left;border-right:1px solid #CCC;padding:0 5px;}
#dock ul li.first{border-left:none;padding:0 5px 0 0;}
#dock ul li.last{border-right:none;padding:0 0 0 5px;}
#robot{position:fixed;bottom:0px;right:50px;z-index:90;}
#ukagaka_voice{position:absolute;width:18px;height:18px;z-index:100;}
#ukagaka_shell .tools{position:absolute;top:-5px;left:10px;color:#999;z-index:2;}
#robot_balloon{position:relative;}
#ukagaka_shell div.ui_1{width:130px;height:319px;color:#AB515D;padding:4px 10px;}
#ukagaka_shell .shell_4{margin-bottom:10px;background:url("https://bangumi.tv/img/ukagaka/shell_4.png") no-repeat top right;}
#ukagaka_shell .shell_4 #ukagaka_voice{top:85px;left:85px;}
.ukagaka_balloon_pink{width:340px;margin-left:-320px;}
.ukagaka_balloon_pink .inner{min-height:50px;padding:10px 10px 5px 10px;background:url("https://bangumi.tv/img/ukagaka/balloon_pink.png") no-repeat top left;}
.ukagaka_balloon_pink_bottom{width:337px;height:11px;background:url("https://bangumi.tv/img/ukagaka/balloon_pink.png") no-repeat bottom left;}
#robot_balloon a{color:#444;}
#robot_balloon a:hover{color:#02A3FB;}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2){
#headerSearch input.search{background-image:url('https://bangumi.tv/img/rc2/ico_search_2x.png');background-size:20px 20px;}
#headerNeue2 div.bg{background-image:url('https://bangumi.tv/img/rc3/bg_musume_2x.png');background-size:280px 75px;}
#headerNeue2 a.logo{background-image:url('https://bangumi.tv/img/rc3/logo_2x.png');background-size:130px 35px;}
}
@media (max-width: 640px){
#wrapperNeue{min-width:100%;}
#headerNeue2{min-width:100%;}
#headerNeue2 .headerNeueInner{width:auto;padding:0 5px;}
#headerNeue2 #headerSearchWrapper{display:none;}
#navNeue2{position:relative;}
#navNeue2{width:auto;display:none;opacity:0;-webkit-box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);-moz-box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);}
#headerNeue2 .menuCompact{float:left;display:block;line-height:25px;font-size:14px;font-weight:bold;margin:8px 0 0 0;padding:5px 15px;-moz-border-radius:100px;-webkit-border-radius:100px;border-radius:100px;transition:all 500ms ease;background:#F1F1F1;color:#777;}
#headerNeue2 .menuCompact:hover,#navMenuNeueToggle:checked+label.menuCompact{background:#F09199;color:#FFF;}
#navMenuNeueToggle+label.menuCompact:after{content:"菜单";}
#navMenuNeueToggle:checked+label.menuCompact:after{content:"关闭";}
#navMenuNeueToggle:checked~#navNeue2{display:block;opacity:1;min-width:100%;top:99px;left:0;position:absolute;z-index:98;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(254, 254, 254, 0.9);}
#navMenuNeueToggle:checked~#headerSearchWrapper{display:block;position:absolute;z-index:98;min-width:100%;left:0;top:50px;background:#FFF;}
#headerSearchWrapper #headerSearch{float:none;margin:10px 10px;}
#headerSearchWrapper #headerSearch input.textfield{width:80%;line-height:25px;}
#headerSearch input.search{float:right;margin:2px 0 0 0;}
#navNeue2 #menuNeue{width:100%;}
#navMenuNeue li{float:none;}
#navMenuNeue li a,#navMenuNeue li a.top,#navMenuNeue li a.focus,#navMenuNeue li a.top_lite,#navNeue2 #navMenuNeue li a.chl,#navNeue2 #navMenuNeue li a.anime,#navNeue2 #navMenuNeue li a.real,#navMenuNeue li:hover a.top span{display:block;float:none;-moz-border-radius:0;-webkit-border-radius:0;border-radius:0;margin:0;padding:0;border:none;line-height:30px;}
#navMenuNeue li a,#navNeue2 #navMenuNeue li a.chl,#navMenuNeue li a.top,#navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{padding:5px 0;line-height:30px;}
#navNeue2 #navMenuNeue li a.top:hover,#navNeue2 #navMenuNeue li a.top_lite:hover,#navNeue2 #navMenuNeue li:hover a.top,#navNeue2 #navMenuNeue li a.focus:hover{background:#F09199;color:#FFF;padding:5px 0;border:none;line-height:30px;}
#navMenuNeue li ul{width:50%;}
#navMenuNeue li ul li .sep{width:90%;}
#main{width:auto;}
.columns{width:auto;}
.column{float:none;}
div#header{padding:15px 5px 5px 5px;}
#columnSubjectBrowserA,#columnSubjectBrowserB{width:auto;max-width:100%;margin:10px 0 0 0;}
ul.collect_dropmenu{left:auto;right:0;}
#dock{right:5px;}
#robot{right:5px;}
.ukagaka_balloon_pink{width:250px;margin-left:-230px;}
.ukagaka_balloon_pink .inner{background-size:100% auto;}
.ukagaka_balloon_pink_bottom{width:250px;background-size:100% auto;}
#footer{margin:10px 0;}
#footer .copyright{margin:0 10px;}
#footer #footerLinks{padding:10px 0;}
}
/*! CSS Used from: https://bangumi.tv/min/g=css?r351 */
body{margin:0px;padding:0px;font-size:12px;font-family:'SF Pro SC','SF Pro Display','PingFang SC','Lucida Grande','Helvetica Neue',Helvetica,Arial,Verdana,sans-serif,"Hiragino Sans GB";line-height:150%;background-color:#FFF;color:#000;}
body,div,dl,dt,dd,ul,li,h1,h2,form,input,select{margin:0;padding:0;}
input,select{vertical-align:baseline;}
input[type=text]{background:#FFF;color:#000;font-size:12px;outline:none;}
label{cursor:pointer;}
img{border:0;}
strong{font-weight:bold;font-style:normal;}
ul{list-style:none;}
small{-webkit-text-size-adjust:none;text-size-adjust:none;}
.clear{clear:both;line-height:0;font-size:0;}
.clearit:after{content:".";display:block;height:0;clear:both;visibility:hidden;}
.clearit{zoom:1;}
.ll{float:left;}
::-moz-focus-inner{border:none;padding:0;}
a:link,a:active,a:visited{text-decoration:none;color:#444;}
a:hover{text-decoration:none;color:#02A3FB;}
hr.board{height:1px;border:0;background-color:#EEE;color:#EEE;}
#multipage{margin:10px 0;}
ul.cata4c{padding:0 5px;}
ul.cata4c li{width:25%;float:left;margin-top:5px;padding-bottom:5px;}
a.nav,a.nav:link,a.nav:visited,a.nav:active{color:#EFEFEF;border:0;text-decoration:none;}
a.nav:hover{color:#fff;}
a.l,a.l:link,a.l:visited,a.l:active{color:#0084B4;border:0;text-decoration:none;}
a.l:hover{color:#02A3FB;text-decoration:underline;}
div.page_inner{padding:10px 0;}
a.p,a.p:active,a.p:visited{padding:4px 8px;margin-right:4px;font-size:12px;text-decoration:none;font-weight:normal;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;color:#555;background:#EEE;}
a.p:hover{color:#FFF;background:#0084B4;}
span.p_edge{margin:0 4px;font-size:11px;color:#777;font-weight:normal;padding:4px 5px 3px;}
strong.p_cur{margin-right:4px;font-size:12px;color:#FFF;font-weight:normal;padding:4px 8px;display:inline;background:#F09199;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
a.p_pages input.inputtext{padding:3px 5px;}
small.grey{font-size:10px;color:#999;}
.level1{font-size:19px;}
#main{margin:0 auto;width:1000px;}
#headerSearch{position:absolute;right:0;margin:10px 0 0 0;border:1px solid #F19299;background:rgba(255, 255, 255, 0.8);-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-moz-box-shadow:0px 1px 0 rgba(255, 255, 255, 0.3), 0px 0 2px rgba(0, 0, 0, 0.3) inset;-webkit-box-shadow:0px 1px 0 rgba(255, 255, 255, 0.3), 0px 0 2px rgba(0, 0, 0, 0.3) inset;box-shadow:0 0 1px rgba(0, 0, 0, 0.3) inset, 0px 1px 0 rgba(255, 255, 255, 0.3);}
#headerSearch .inner{padding:3px 5px;}
#headerSearch input.textfield{background:transparent;line-height:20px;width:160px;border:none;-webkit-appearance:caret;}
#headerSearch input.textfield:focus{outline:none;}
#headerSearch input.search{text-indent:-9999px;width:20px;height:20px;background:transparent url('https://bangumi.tv/img/rc2/ico_search.png') 50% 50% no-repeat;color:#FFF;border:none;cursor:pointer;}
#headerSearch input.search:focus{outline:none;}
#header{padding:15px 0 10px 0;position:relative;}
#header h1{color:#3E3E3E;font-size:20px;font-size-adjust:none;font-stretch:normal;font-style:normal;font-variant:normal;font-weight:bold;display:inline;}
.columns{padding:0 0 10px 0;width:1000px;}
.column{float:left;}
#columnA{margin:10px 0 0 0;width:670px;}
#columnB{margin:10px 0 0 20px;width:280px;}
div.SidePanel,div.SidePanelLow{font-size:12px;margin-bottom:15px;padding:0 10px 10px 10px;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;-webkit-box-shadow:0px 5px 10px rgba(0, 0, 0, 0.09);-moz-box-shadow:0px 5px 10px rgba(0, 0, 0, 0.09);box-shadow:0px 5px 10px rgba(0, 0, 0, 0.09);border:1px solid #F0F0F0;background-image:-webkit-linear-gradient(#f8f9f9, #f0f1f2 99%, #e4e4e5);background-image:-o-linear-gradient(#f8f9f9, #f0f1f2 99%, #e4e4e5);background-image:linear-gradient(#f8f9f9, #f0f1f2 99%, #e4e4e5);background-repeat:no-repeat;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f9f9', endColorstr='#ffe4e4e5', GradientType=0);background-size:40px 40px;background-repeat:repeat-x;}
div.SidePanel h2,div.SidePanelLow h2{height:45px;line-height:40px;font-size:13px;font-weight:normal;padding:0;color:#555;text-shadow:#FFF 1px 1px 0px;}
div.SidePanelLow{padding:0;}
div.SidePanelLow h2{padding:0 10px;height:40px;}
div.SidePanelLow div.innerWrapper{margin:5px 10px;}
div.menu_inner{padding:5px 10px;font-size:12px;margin-bottom:15px;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;background:#FAFAFA;border:1px solid #e8e8e8;-webkit-box-shadow:0px 0px 4px #EAEAEA, inset 0 1px 1px rgba(255, 255, 255, 0.3);-moz-box-shadow:0px 0px 4px #EAEAEA, inset 0 1px 1px rgba(255, 255, 255, 0.3);box-shadow:0px 0px 4px #EAEAEA, inset 0 1px 1px rgba(255, 255, 255, 0.3);}

#menuNeue{width:980px;margin:0 auto;padding:0;text-align:center;}
#navMenuNeue li{float:left;}
#navMenuNeue li a.top,#navMenuNeue li a.focus,#navMenuNeue li a.top_lite{display:block;font-size:14px;font-weight:bold;text-align:center;text-shadow:1px 1px #eee;float:left;padding:0 15px;line-height:35px;color:#777;}
#navMenuNeue li a.top:hover,#navMenuNeue li a.top_lite:hover,#navMenuNeue li:hover a.top{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);color:#333;}
#navMenuNeue li a.focus:hover{-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.1);}
#navMenuNeue li:hover a.top span{display:block;float:left;text-shadow:1px 1px #CCC;}
#navMenuNeue li:hover a.focus span{background:#FFF;}
#navMenuNeue li a.focus,#navMenuNeue li a.top_lite:hover{display:block;font-size:14px;font-weight:bold;text-align:center;text-shadow:1px 1px #fff;color:#369CF8;}
#navMenuNeue a.nav{display:block;}
#navMenuNeue li ul li .sep{display:block;width:98px;margin:2px 0;padding:2px 10px;line-height:16px;text-align:left;background-color:#F1F1F1;color:#555;border-bottom:1px #eee solid;}
#navMenuNeue li ul{display:block;position:absolute;left:-999em;z-index:90;width:118px;padding:0;background:#FFF;background:-webkit-gradient(linear, left top, left bottom, color-stop(0.8, rgba(254, 254, 254, 0.9)), color-stop(1, rgba(250, 250, 250, 0.95)));background:-moz-linear-gradient(center top, rgba(254, 254, 254, 0.9) 80%, rgba(250, 250, 250, 0.95) 100%);background:linear-gradient(center top, rgba(254, 254, 254, 0.9) 80%, rgba(250, 250, 250, 0.95) 100%);-moz-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);-webkit-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.3), 0 2px 2px hsla(0, 0%, 0%, 0.2);-moz-border-radius:0 0 5px 5px;-webkit-border-radius:0 0 5px 5px;border-radius:0 0 5px 5px;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(254, 254, 254, 0.9);}
#navMenuNeue li ul li{float:none;}
#navMenuNeue li:hover ul{left:auto;}
#navMenuNeue li ul li a{color:#0187C5;text-align:center;padding:3px 0px;color:#0084B4;}
#navMenuNeue li ul li a:hover{color:#FFF;background:#4F93CF;background:-moz-linear-gradient(top, #6BA6D8, #4F93CF);background:-o-linear-gradient(top, #6BA6D8, #4F93CF);background:-webkit-gradient(linear, left top, left bottom, from(#6BA6D8), to(#4F93CF));}
#navMenuNeue li ul li:first-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:0;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:5px;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:0;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:5px;border-top-right-radius:5px;border-bottom-right-radius:0;border-bottom-left-radius:0;border-top-left-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#navMenuNeue li ul li:last-child a:hover{-webkit-border-top-right-radius:0;-webkit-border-bottom-right-radius:5px;-webkit-border-bottom-left-radius:5px;-webkit-border-top-left-radius:0;-moz-border-radius-topright:0;-moz-border-radius-bottomright:5px;-moz-border-radius-bottomleft:5px;-moz-border-radius-topleft:0;border-top-right-radius:0;border-bottom-right-radius:5px;border-bottom-left-radius:5px;border-top-left-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#navMenuNeue li ul.explore{width:170px;text-align:left;}
#navMenuNeue li ul.explore li{border-bottom:1px solid #eee;}
#navMenuNeue li ul.explore li a{display:inline-block;text-align:left;padding:2px 10px;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;}
#navMenuNeue li ul.explore li a:hover{background:#369CF8;}
#navMenuNeue li ul.explore li span{display:block;padding:2px 0;}
#navMenuNeue li ul.explore li.group{padding:5px 10px;}
#navMenuNeue li ul.explore li.group span.root{display:inline-block;color:#888;background:#F5F5F5;font-weight:bold;padding:2px 5px;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;}
#navMenuNeue li ul.explore li.single a{display:block;background:none;text-align:left;padding:5px 20px;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;color:#0084B4;}
#navMenuNeue li ul.explore li.single a:hover{background:#4F93CF;color:#FFF;background:-moz-linear-gradient(top, #6BA6D8, #4F93CF);background:-o-linear-gradient(top, #6BA6D8, #4F93CF);background:-webkit-gradient(linear, left top, left bottom, from(#6BA6D8), to(#4F93CF));}
#navMenuNeue li ul.explore li.single:first-child a:hover{-webkit-border-top-right-radius:5px;-webkit-border-bottom-right-radius:0;-webkit-border-bottom-left-radius:0;-webkit-border-top-left-radius:5px;-moz-border-radius-topright:5px;-moz-border-radius-bottomright:0;-moz-border-radius-bottomleft:0;-moz-border-radius-topleft:5px;border-top-right-radius:5px;border-bottom-right-radius:0;border-bottom-left-radius:0;border-top-left-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;}
#tagSearch .inner{margin:0;}
#tagSearch input.inputtext{width:90%;}
#tagSearch div.search{float:right;padding:5px 5px 0 0;}
#footer{margin:20px 0 15px 0;padding:0;color:#AAA;}
#footer #footerLinks{display:block;overflow:hidden;background:#f8f8f8;border-top:1px solid #EEE;margin:0 0 0 0;padding:15px 10px;}
#footer #footerLinks li{display:block;float:left;width:25%;}
#footer #footerLinks li dt{font-size:12px;font-weight:bold;margin-bottom:3px;margin-left:15px;padding-bottom:3px;width:177px;border-bottom:1px solid #CCC;}
#footer #footerLinks li dd{margin-bottom:0.2em;margin-left:15px;}
#tagList{line-height:250%;}
#airTimeMenu{position:relative;}
#airTimeMenu li.airYear,#airTimeMenu li#pastAirTime,#airTimeMenu li#futureAirTime{position:relative;}
#airTimeMenu li.airYear a,#airTimeMenu li#pastAirTime a,#airTimeMenu li#futureAirTime a{display:block;}
#wrapperNeue{min-width:980px;}
#headerNeue2{position:relative;min-width:980px;height:50px;background:#FCFCFC;border-bottom:1px solid #DDD;box-shadow:0px 0 0px 1px rgba(250, 250, 250, 0.8);background-color:#FCFCFC;background:-webkit-gradient(linear, left top, left bottom, from(#FCFCFC), to(#FAFAF9));background:-webkit-linear-gradient(top, #FCFCFC, #FAFAF9);background:-moz-linear-gradient(top, #FCFCFC, #FAFAF9);background:-ms-linear-gradient(top, #FCFCFC, #FAFAF9);background:-o-linear-gradient(top, #FCFCFC, #FAFAF9);}
#headerNeue2 div.bg{position:absolute;left:-40px;background:url('https://bangumi.tv/img/rc3/bg_musume.png') 0 -10px no-repeat;width:40px;height:50px;}
#headerNeue2 div.musume_6{background-position:-240px 0;}
#headerNeue2 .headerNeueInner{width:1000px;margin:0 auto;padding:0;position:relative;}
#headerNeue2 a.logo{margin:0 5px 0 0;float:left;display:block;text-indent:-9999px;width:140px;height:50px;background:transparent url('https://bangumi.tv/img/rc3/logo.png') left 50% no-repeat;}
#headerNeue2 #headerSearch{float:right;position:relative;margin:10px 10px 0 10px;-moz-border-radius:100px;-webkit-border-radius:100px;border-radius:100px;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;border:1px solid #DDD;background-color:rgba(255, 255, 255, 0.2);}
#headerNeue2 #headerSearch input.textfield{width:120px;-webkit-appearance:none;-moz-appearance:none;box-shadow:none;}
#headerNeue2 #headerSearch select{padding:4px 0 4px 5px;min-width:35px;border:none;outline:none;box-shadow:none;background-color:transparent;background-image:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;-moz-border-radius:0;-webkit-border-radius:0;border-radius:0;border-right:1px solid #DDD;color:#000;}
#navNeue2{float:left;}
#navNeue2 #menuNeue{width:510px;}
#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{padding:6px 5px 5px 5px;line-height:40px;}
#navNeue2 #navMenuNeue li:hover a.focus span{background:transparent;}
#navNeue2 #navMenuNeue li a.top:hover,#navNeue2 #navMenuNeue li a.top_lite:hover,#navNeue2 #navMenuNeue li:hover a.top,#navNeue2 #navMenuNeue li a.focus:hover{line-height:36px;border-top:2px solid #F09199;-moz-box-shadow:none;-webkit-box-shadow:none;box-shadow:none;color:#333;}
#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{font-weight:normal;text-shadow:none;}
#navNeue2 #navMenuNeue li:hover a.top span{text-shadow:none;}
#navNeue2 #navMenuNeue li a.chl{font-weight:bold;margin:8px 0 0 0;padding:5px 8px;line-height:25px;background-color:#F5F5F5;background:-webkit-gradient(linear, left top, left bottom, from(#F5F5F5), to(#F1F1F1));background:-webkit-linear-gradient(top, #F5F5F5, #F1F1F1);background:-moz-linear-gradient(top, #F5F5F5, #F1F1F1);background:-ms-linear-gradient(top, #F5F5F5, #F1F1F1);background:-o-linear-gradient(top, #F5F5F5, #F1F1F1);}
#navNeue2 #navMenuNeue li a.chl:hover{border:none;line-height:25px;background:#F09199;color:#FFF;text-shadow:none;}
#navNeue2 #navMenuNeue li:hover a.chl{border:none;line-height:25px;background:#F09199;color:#FFF;text-shadow:none;}
#navNeue2 #navMenuNeue li a.anime{padding-left:15px;-moz-border-radius:100px 0 0 100px;-webkit-border-radius:100px 0 0 100px;border-radius:100px 0 0 100px;}
#navNeue2 #navMenuNeue li a.real{padding-right:15px;margin-right:5px;-moz-border-radius:0 100px 100px 0;-webkit-border-radius:0 100px 100px 0;border-radius:0 100px 100px 0;}
#navNeue2 #navMenuNeue li ul{-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-moz-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);-webkit-box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);box-shadow:inset 0 1px 1px hsla(0, 0%, 100%, 0.3), inset 0 -1px 0 hsla(0, 0%, 100%, 0.1), 0 2px 4px hsla(0, 0%, 0%, 0.2);}
#headerNeue2 .menuCompact,#headerNeue2 #navMenuNeueToggle{display:none;}
input[type=text],select{-webkit-transition:border linear 0.2s, box-shadow linear 0.2s;-moz-transition:border linear 0.2s, box-shadow linear 0.2s;transition:border linear 0.2s, box-shadow linear 0.2s;-webkit-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1);-moz-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1);box-shadow:inset 0 1px 3px rgba(0,0,0,0.1);}
input.inputtext{font-size:15px;padding:5px 5px;line-height:22px;width:98%;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;-moz-background-clip:padding;-webkit-background-clip:padding-box;background-clip:padding-box;background-color:#FFF;color:#000;border:1px solid #d9d9d9;}
input.inputtext:focus{-webkit-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);-moz-box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(240, 145, 153, 0.6);border:1px solid #D98D88 #F4A8BC #F4A8BC #D98D88;background-color:#FFF;color:#000;}
input.btnPink{border:0px;background:#F09199;color:#FFF;cursor:pointer;margin:5px 0;padding:5px 25px;font-size:14px;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;line-height:150%;-webkit-appearance:none;}
input.btnPink:hover{background:#369CF8;color:#FFF;}
select.form{border-width:1px;border-style:solid;border-color:#999 #CCC #DDDDDD #999;padding:3px;font-size:14px;}
select.form:focus{border-width:1px;border-style:solid;border-color:#FD0B98 #FFADD1 #FFADD1 #FD0B98;padding:3px;}
span.avatarNeue{display:inline-block;background:transparent no-repeat 50% 50%;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;-webkit-box-shadow:inset #BBB 0px 0 2px 0px;-moz-box-shadow:inset #BBB 0px 0 2px 0px;box-shadow:inset #BBB 0px 0 2px 0px;-webkit-transition:border linear 0.2s, box-shadow linear 0.2s;-moz-transition:border linear 0.2s, box-shadow linear 0.2s;transition:border linear 0.2s, box-shadow linear 0.2s;}
span.avatarSize32{width:32px;height:32px;background-size:cover;}
a.avatar:hover span.avatarNeue{-moz-box-shadow:inset #0187C5 0px 0px 4px 0px;-webkit-box-shadow:inset #0187C5 0px 0px 4px 0px;box-shadow:inset #0187C5 0px 0px 4px 0px;}
#dock{position:fixed;bottom:0px;right:30px;z-index:99;}
#dock .content{padding:3px 10px;font-size:12px;position:relative;-webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);-moz-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.3), inset 0 0 0px 1px rgba(255, 255, 255, 0.2);-moz-border-radius:5px 5px 0 0;-webkit-border-radius:5px 5px 0 0;border-radius:5px 5px 0 0;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(250, 250, 250, 0.8);color:#666;}
#dock ul li{float:left;border-right:1px solid #CCC;padding:0 5px;}
#dock ul li.first{border-left:none;padding:0 5px 0 0;}
#dock ul li.last{border-right:none;padding:0 0 0 5px;}
#robot{position:fixed;bottom:0px;right:50px;z-index:90;}
#ukagaka_voice{position:absolute;width:18px;height:18px;z-index:100;}
#ukagaka_shell .tools{position:absolute;top:-5px;left:10px;color:#999;z-index:2;}
#robot_balloon{position:relative;}
#ukagaka_shell div.ui_1{width:130px;height:319px;color:#AB515D;padding:4px 10px;}
#ukagaka_shell .shell_4{margin-bottom:10px;background:url("https://bangumi.tv/img/ukagaka/shell_4.png") no-repeat top right;}
#ukagaka_shell .shell_4 #ukagaka_voice{top:85px;left:85px;}
.ukagaka_balloon_pink{width:340px;margin-left:-320px;}
.ukagaka_balloon_pink .inner{min-height:50px;padding:10px 10px 5px 10px;background:url("https://bangumi.tv/img/ukagaka/balloon_pink.png") no-repeat top left;}
.ukagaka_balloon_pink_bottom{width:337px;height:11px;background:url("https://bangumi.tv/img/ukagaka/balloon_pink.png") no-repeat bottom left;}
#robot_balloon a{color:#444;}
#robot_balloon a:hover{color:#02A3FB;}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2){
#headerSearch input.search{background-image:url('https://bangumi.tv/img/rc2/ico_search_2x.png');background-size:20px 20px;}
#headerNeue2 div.bg{background-image:url('https://bangumi.tv/img/rc3/bg_musume_2x.png');background-size:280px 75px;}
#headerNeue2 a.logo{background-image:url('https://bangumi.tv/img/rc3/logo_2x.png');background-size:130px 35px;}
}
@media (max-width: 640px){
#wrapperNeue{min-width:100%;}
#headerNeue2{min-width:100%;}
#headerNeue2 .headerNeueInner{width:auto;padding:0 5px;}
#headerNeue2 #headerSearchWrapper{display:none;}
#navNeue2{position:relative;}
#navNeue2{width:auto;display:none;opacity:0;-webkit-box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);-moz-box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);box-shadow:0px 0px 0px 2px rgba(0, 0, 0, 0.04);}
#headerNeue2 .menuCompact{float:left;display:block;line-height:25px;font-size:14px;font-weight:bold;margin:8px 0 0 0;padding:5px 15px;-moz-border-radius:100px;-webkit-border-radius:100px;border-radius:100px;transition:all 500ms ease;background:#F1F1F1;color:#777;}
#headerNeue2 .menuCompact:hover,#navMenuNeueToggle:checked+label.menuCompact{background:#F09199;color:#FFF;}
#navMenuNeueToggle+label.menuCompact:after{content:"菜单";}
#navMenuNeueToggle:checked+label.menuCompact:after{content:"关闭";}
#navMenuNeueToggle:checked~#navNeue2{display:block;opacity:1;min-width:100%;top:99px;left:0;position:absolute;z-index:98;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);background:rgba(254, 254, 254, 0.9);}
#navMenuNeueToggle:checked~#headerSearchWrapper{display:block;position:absolute;z-index:98;min-width:100%;left:0;top:50px;background:#FFF;}
#headerSearchWrapper #headerSearch{float:none;margin:10px 10px;}
#headerSearchWrapper #headerSearch input.textfield{width:80%;line-height:25px;}
#headerSearch input.search{float:right;margin:2px 0 0 0;}
#navNeue2 #menuNeue{width:100%;}
#navMenuNeue li{float:none;}
#navMenuNeue li a,#navMenuNeue li a.top,#navMenuNeue li a.focus,#navMenuNeue li a.top_lite,#navNeue2 #navMenuNeue li a.chl,#navNeue2 #navMenuNeue li a.anime,#navNeue2 #navMenuNeue li a.real,#navMenuNeue li:hover a.top span{display:block;float:none;-moz-border-radius:0;-webkit-border-radius:0;border-radius:0;margin:0;padding:0;border:none;line-height:30px;}
#navMenuNeue li a,#navNeue2 #navMenuNeue li a.chl,#navMenuNeue li a.top,#navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top,#navNeue2 #navMenuNeue li a.focus,#navNeue2 #navMenuNeue li a.top_lite{padding:5px 0;line-height:30px;}
#navNeue2 #navMenuNeue li a.top:hover,#navNeue2 #navMenuNeue li a.top_lite:hover,#navNeue2 #navMenuNeue li:hover a.top,#navNeue2 #navMenuNeue li a.focus:hover{background:#F09199;color:#FFF;padding:5px 0;border:none;line-height:30px;}
#navMenuNeue li ul{width:50%;}
#navMenuNeue li ul li .sep{width:90%;}
#main{width:auto;}
.columns{width:auto;}
.column{float:none;}
div#header{padding:15px 5px 5px 5px;}
#columnA,#columnB{width:auto;max-width:100%;margin:10px 0 0 0;}
#columnA,#columnB{padding:0 5px;}
div.menu_inner{width:auto;}
#dock{right:5px;}
#robot{right:5px;}
.ukagaka_balloon_pink{width:250px;margin-left:-230px;}
.ukagaka_balloon_pink .inner{background-size:100% auto;}
.ukagaka_balloon_pink_bottom{width:250px;background-size:100% auto;}
#footer{margin:10px 0;}
#footer .copyright{margin:0 10px;}
#footer #footerLinks{padding:10px 0;}
}